.patreon-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  text-align: center;
  margin-bottom: 64px;
}

.patreon-notice {
  text-align: center;
  background-color: #e85b46;
  padding: 10px;
  color: white;
  font-weight: bold;
  margin-top: 10px;
}

.patreon-banner {
  display: flex;
  height: max-content;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.patreon-banner-info {
  max-width: 600px;
  padding: 15px;
  text-align: center;
}

.patreon-banner-sale {
  display: flex;
  height: 350px;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.akrew-pro-tabs-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.akrew-pro-tab {
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  border-radius: 6px;
  cursor: pointer;
}

.patreon-tiers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  row-gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.tier-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.tier-icon {
  margin-bottom: 16px;
}

.tier-card {
  display: flex;
  flex-direction: column;
  padding: 8px;
  height: 100%;
  border-radius: 12px;
}

.tier-card-header {
  text-align: left;
  margin-left: 16px;
  margin-right: 16px;
}

.tier-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.tier-price {
  font-weight: bold;
  font-size: 35px;
  margin-bottom: -10px;
}

.tier-benefits {
  text-align: left;
  list-style: none;
  margin-right: 8px;
  list-style-position: outside;
  text-indent: -2.2em;
}

li.tier-benefit-item:not(:last-child) {
  margin-bottom: 16px;
}

.tier-benefits .tier-info-check {
  margin-bottom: -4px;
  margin-right: 16px;
  font-size: 18px;
  color: #22c55e;
}

.tier-btn-bar {
  margin-top: auto;
  align-self: center;
}

.patreon-btn {
  display: inline-block;
  font-size: 18px;
  padding: 10px 56px;
  margin-top: 10px;
  border-radius: 20px;
  text-decoration: none;
}

.patreon-btn.popular-btn {
  padding: 10px 44px;
}

.patreon-discord {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.akrew-pro-page-footer {
  margin-top: 40px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 600px) {
  .akrew-pro-show-more-mobile {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .akrew-pro-show-more-web {
    display: none;
  }

  .akrew-pro-show-more-mobile {
    margin-bottom: 20px;
  }

  .patreon-banner-sale {
    height: 150px;
  }
}
