.wishlist-name {
  display: inline-block;
  font-size: 25px;
  font-weight: bold;
}

.wishlist-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.wishlist-input input {
  width: auto;
  font-size: 15px;
}

.wishlist-input.modal {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.wishlist-input.modal input {
  width: 100%;
  font-size: 15px;
}

.wishlist-actions-modal {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.wishlist-actions-modal button {
  margin: 0px;
}

.wishlist-btn {
  margin: 0 10px;
}

.wishlist-content {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
}

.wishlist-control {
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid lightgray;
}

@media only screen and (max-width: 600px) {
  .wishlist-control {
    width: 100%;
    padding-right: 0;
    margin-right: 0;
    border-right: none;
    margin-bottom: 15px;
  }
}

.wishlist-links {
  margin-top: 15px;
}
