.messages {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
  flex: 1;
  max-height: 80vh;
}

.messages-container {
  border-bottom: 1px solid rgb(123, 123, 123, 0.3);
  flex: 1;
  margin-bottom: 10px;
  overflow: auto;
  padding-top: 10px;
  padding: 10px 15px;
}

.messages-container::-webkit-scrollbar {
  display: none;
}

.add-pref-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: gray;
  text-align: center;
  margin-bottom: 8px;
}

.messages-link-warning {
  color: red;
  text-align: center;
  font-weight: bold;
}

.messages-chat-bar {
  display: flex;
}

.messages-load-more {
  display: flex;
  justify-content: center;
}

.messages-load-more-button {
  display: flex;
  font-size: 12px;
  cursor: pointer;
}

.messages-load-more-button:hover {
  background-color: transparent;
}

.message-container {
  flex-basis: 40%;
  margin-bottom: 10px;
}

.message-content {
  padding: 10px;
  border-radius: 5px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  position: relative;
}

.message-from {
  display: flex;
  justify-content: space-between;
}

.report-message-icon {
  position: absolute;
  right: -4px;
  top: 12px;
  color: #f5222d;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.1s linear;
}

.report-message-icon:hover {
  color: #ff4d4f;
}

.msg-profile-img {
  border-radius: 50%;
  max-width: 35px;
  margin-right: 7px;
}

.message-user {
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.message-time {
  color: gray;
  font-size: 12px;
}

.mod-premades {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mod-premade {
  font-size: 14px;
  padding: 2px 10px;
  min-width: auto;
  margin-right: 7px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.custom-message {
  font-size: 15px;
  font-family: 'Baloo 2';
}

.custom-messages-collapse {
  border: none;
  margin-right: 5px;
  border-bottom: none;
}

.custom-messages-collapse:hover {
  cursor: pointer;
}

.custom-messages-collapse svg {
  color: #838383;
  font-size: 18px;
  vertical-align: middle;
}

.premade-rm {
  padding: 0 !important;
  vertical-align: middle;
  margin-left: 7px;
}

.premade-rm:hover {
  background: none !important;
  color: black;
}

.chat-bar {
  padding: 0 15px;
}

@media only screen and (max-width: 600px) {
  .messages {
    max-height: unset;
  }

  .message-from {
    flex-direction: column;
  }

  .message-container {
    flex-basis: 80%;
  }

  .message-content {
    max-width: 300px;
  }

  .messages-chat-bar {
    margin-bottom: 0px;
    resize: none;
  }

  .mod-premades {
    display: flex;
    overflow: auto;
    flex-wrap: unset;
  }
}
