.listings-action-bar {
  display: flex;
  margin-bottom: 15px;
  gap: 4px;
}

.listings-slider {
  display: flex;
  overflow-x: scroll;
  gap: 8px;
  width: 100%;
}

.listings-slider::-webkit-scrollbar {
  background-color: transparent;
}

.listings-slider::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.listings-slider:hover::-webkit-scrollbar-thumb {
  background-color: #909397;
}

.listing-row {
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.listing-content {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  height: 100%;
}

.listing-btn-container {
  margin-bottom: 10px;
}

.listing-img-container {
  display: block;
  position: relative;
  max-height: 150px;
  max-width: 150px;
  overflow: hidden;
  border-radius: 6px;
  margin: auto;
}

.listing-item-img {
  width: 100px;
  vertical-align: middle;
  border-radius: 10px;
}

.item-icon-no-details {
  display: flex;
  justify-content: center;
  align-items: center;
}

.listing-item-img-gallery {
  width: 100%;
}

.listing-icon-make-offer {
  width: 24px;
  font-size: 30px;
  margin-top: 4px;
}

.listing-item-prop-img {
  max-width: 50%;
}

.listing-item-prop-img.gallery {
  max-width: 30%;
}

.listing-item-prop-img.listing-page {
  max-width: 100%;
}

.listing-icon-item-name {
  width: 70px;
  text-align: center;
  margin-top: 4px;
  line-height: 18px;
}

.listing-diy-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 0;
  right: 0;
}

.listing-img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 5px;
}

.listing-product-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.listing-date {
  font-size: 13px;
  color: gray;
  position: absolute;
  bottom: 0;
  right: 0;
}

.listing-date-compact {
  font-size: 14px;
  color: gray;
  position: absolute;
  height: 16px;
  left: 0;
  bottom: 0;
}

.listing-date-compact-slider {
  display: block;
  text-align: right;
  font-size: 14px;
  color: gray;
}

/* ListingProductInfo */

.listing-compact-header {
  margin: 0;
  font-size: 18px;
  text-wrap: nowrap;
  text-align: left;
}

.exchange-icon {
  min-width: 32px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.ihavethis-compact-icon {
  flex: 1;
  display: block;
  width: 83px;
}

.ihavethis-compact-icon.multi {
  width: 100%;
}

.ihavethis-multi {
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
}

/* ================== */

.trading-for {
  margin-top: 8px;
  margin-bottom: 0;
}

.no-listings {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  color: gray;
}

.price-line {
  display: flex;
  align-items: center;
}

.prices-row {
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
  flex: 1;
}

.prices-or-compact {
  font-weight: bold;
  font-size: 18px;
  display: flex;
  margin: 0px 4px;
  margin-top: 25px;
}

.prices-or-compact.show-details {
  margin-top: 30px;
}

.listing-bells {
  display: flex;
  align-items: center;
}

.role-badge {
  margin-right: 5px;
}

.listing-filters-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.listing-filters-left {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.listing-filters-left.row-2 {
  align-items: center;
}

.listing-filters-item-search {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.listing-filters-item-search .search-select {
  min-width: 130px;
}

.clear-item-search {
  color: #f5222d;
  cursor: pointer;
}

.items-category-active.create-trade-btn {
  margin: 0px;
}

.create-trade-btn {
  display: flex;
  align-items: center;
  gap: 4px;
}

.listing-filters-right {
  display: flex;
  flex-direction: column;
  margin-top: 2px;
}

.listing-filters-redirect {
  font-weight: bold;
  margin: 5px 0px 10px 0px;
}

.listing-sort {
  min-width: 175px;
}

.home-listing-button {
  text-decoration: underline !important;
}

.listing-action-bar button {
  width: 100%;
}

.move-wishlist-dropdown {
  min-width: 100px;
}

.min-max-filter-info {
  display: flex;
  align-items: center;
}

.min-max-filter-name {
  white-space: normal;
}

.fa-spin {
  animation: fa-spin 1.5s infinite linear;
}

.listing-search-options {
  margin-top: 15px;
}

.listing-options-label {
  margin-bottom: 8px;
}

.listing-options-group {
  display: block;
  margin-bottom: 8px;
}

.beacon {
  margin-left: 25px;
  font-size: 20px;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

.remove-active-filter-btn {
  background: none;
  font-size: 16px;
  padding: 0;
  min-width: 0;
}

.remove-active-filter-btn:hover {
  background-color: none;
}

#listings-apply-filters-btn {
  margin-right: 8px;
}

.greater-affix-opt {
  margin-left: 10px;
}

.greater-affix-opt-btn {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  padding: 0;
  background: none;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@media only screen and (max-width: 600px) {
  .listings-header {
    text-align: center;
  }

  .listing-filters-left {
    flex-direction: column;
  }

  .listing-filters-right {
    display: none;
  }

  .listing-filters-item-search {
    margin-bottom: 8px;
  }

  .listing-content {
    flex-direction: column;
  }

  .listing-action-bar {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    align-items: center;
  }

  .listing-btn-container {
    margin-right: 10px;
    margin-top: 0px !important;
  }

  .listing-sort {
    margin-bottom: 10px;
  }

  .listings-filters-btn-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .listings-slider::-webkit-scrollbar-thumb {
    background-color: #909397;
  }

  .listing-icon-make-offer {
    width: 13px;
    font-size: 15px;
  }

  .listing-item-prop-img {
    max-width: 100%;
  }

  /* ListingProductInfo */

  .listing-compact-header {
    font-size: 14px;
  }

  .exchange-icon {
    min-width: 16px;
    font-size: 16px;
    margin-top: 38px;
  }

  .exchange-icon.show-details {
    margin-top: 42px;
  }

  .ihavethis-compact-icon {
    max-width: 70px;
  }

  .prices-or-compact {
    font-size: 16px;
    margin-top: 8px;
  }

  .prices-or-compact.show-details {
    margin-top: 16px;
  }

  /* ListingIcon */
  .listing-icon-item-name {
    width: 35px;
    font-size: 10px;
    line-height: 10px;
  }
}
