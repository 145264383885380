:root {
  --bg: #242526;
  --nav-size: 60px;
  --border: 2px solid #474a4d;
  --border-radius: 20px;
  --speed: 500ms;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
  cursor: pointer;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

nav ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.menu-container {
  text-align: center;
}

.nav {
  display: flex;
  align-items: center;
  text-align: left;
}

.nav__menu {
  display: flex;
  align-items: center;
}

.nav .a {
  display: block;
  padding: 0 16px;
  line-height: inherit;
  cursor: pointer;
}

.nav__menu-item {
  display: inline-block;
  position: relative;
  margin: 0 10px;
}

.nav__menu-item:hover .nav__submenu {
  display: block;
}

.nav__submenu {
  font-weight: 300;
  text-transform: none;
  display: none;
  position: absolute;
}
