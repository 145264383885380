.modal {
  font-size: 15px;
  z-index: 1000;
}

.modal-container {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(0, 0, 0, 0.5);
  z-index: 100;
}

.modal-content {
  padding: 20px;
  border-radius: 5px;
  margin: 10px;
  max-height: 80vh;
  max-width: 80vw;
  overflow: auto;
}

.modal-body {
  padding: 16px 0;
}

.modal-btn-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  /* border-top: 2px dashed #88c9ff; */
}

.disabled-confirm-btn {
  cursor: not-allowed !important;
  color: white !important;
}

@media only screen and (max-width: 600px) {
  .modal-content {
    overflow-y: visible;
    max-width: 100vw;
  }
}
