.home-header {
  margin: 0;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 15px;
}

.home-selling {
  margin-right: 75px;
}

.latest-section {
  margin-bottom: 50px;
}

.home-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-see-all {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.home-banner {
  text-align: center;
  background: url('https://cdn.nookazon.com/traderie/home/banner.jpg');
  padding: 40px 0;
  background-size: cover;
  border-radius: 20px;
  color: black;
}

.home-banner h2 {
  font-size: 40px;
  margin-top: 0px;
  margin-bottom: 10px;
  line-height: 1.1;
}

.home-banner a {
  font-size: 20px;
  padding: 10px 30px;
}

@media only screen and (max-width: 600px) {
  .home-listings {
    flex-direction: column;
  }

  .home-selling {
    margin-right: 0;
  }

  .home-see-all {
    margin-bottom: 15px;
  }

  .home-banner {
    margin-top: 20px;
  }
}

.home-catalog {
  display: block;
  margin-bottom: 16px;
}

.home-catalog h2 {
  display: none;
}

.home-catalog img {
  border-radius: 20px;
}

.home-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}
