.comp-container {
  position: relative;
}

.comp {
  display: block;
  aspect-ratio: 1;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.comp-votes {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgb(20, 20, 20);
  border-radius: 20px;
  padding: 2px 15px;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
}

.comp-create {
  max-width: 700px;
}

.comp-create-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}

.comp-create-item {
  position: relative;
}

.comp-item-rm {
  color: red;
}

.comp-item-icon {
  max-height: 86px;
  max-width: 86px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}

.comp-admin-bar {
  display: flex;
  gap: 5px;
  margin-top: 5px;
}

.comments-input {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 10px;
}

.comments-input button {
  white-space: pre;
}

.comp-comment {
  display: flex;
  align-items: center;
  gap: 10px;
}

.comp-comment-user {
  display: block;
  font-weight: bold;
  font-size: 18px;
}

.comp-comment-info {
  display: block;
  font-size: 14px;
  color: gray;
}

.comp-tags {
  display: flex;
  margin-top: 10px;
}

.comp-list {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
}

.comp-list-items {
  padding: 0;
  background: none;
  color: gray;
  min-width: auto;
}

@media only screen and (max-width: 900px) {
  .comp-list-container {
    padding-bottom: 1rem;
  }

  .comp-list {
    margin-bottom: 12px;
  }
}
