.comps {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.comps-page-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comps-page-top .btn {
  text-decoration: none;
}

.comps-page {
  display: flex;
}

.comps-page-right {
  flex: 1;
}

.sort-bar {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comp-page-top {
  display: flex;
  gap: 20px;
  height: 600px;
}

.comp-page-top h1 {
  margin-top: 0;
  margin-bottom: 10px;
}

.comp-page-user {
  display: flex;
  margin-top: 0;
  margin-bottom: 10px;
}

.comp-page-desc {
  margin-bottom: 10px;
}

.comp-page-left {
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.comp-page-left img {
  min-width: 100%;
  min-height: 100%;
  width: unset;
  object-fit: cover;
}

.comp-page-right {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

.comp-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comp-vote-container {
  font-size: 20px;
  font-weight: bold;
}

.comp-vote {
  background-color: transparent;
  padding: 0;
  color: inherit;
  min-width: auto;
  margin-right: 5px;
  font-size: 20px;
}

.comp-vote svg {
  vertical-align: middle;
}

.comp-vote:hover {
  background-color: transparent;
}

.comp-page-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  gap: 12px;
  overflow-y: auto;
}

.comp-page-items-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12%, 1fr));
  gap: 12px;
  overflow-y: wrap;
}

.comp-report {
  display: flex;
  justify-content: flex-end;
}

.comp-report button {
  padding: 0;
  background: none;
  color: gray;
  min-width: auto;
}

.comp-report button:hover {
  background: none;
  color: red;
}

@media only screen and (max-width: 600px) {
  .comp-page-left {
    height: 300px;
  }

  .comp-page-top {
    flex-direction: column;
    height: auto;
    gap: 0;
  }

  .comp-page-items {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }
}

@media only screen and (max-width: 900px) {
  .comp-page-items-list {
    grid-template-columns: repeat(auto-fill, minmax(35%, 1fr));
    padding-bottom: 32px;
  }
}
