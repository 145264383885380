.ae-fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2147483644;
  width: 100%;
}

.ae-video {
  max-width: 350px;
  margin-left: auto;
  margin-right: 0;
}

.ae-anchor {
  position: relative;
  background-color: rgba(245, 245, 245, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 5px;
  text-align: center;
}

.ae-anchor-close {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #7b7b7b;
  box-sizing: border-box;
  color: #7b7b7b;
  cursor: pointer;
  display: flex;
  height: 25px;
  justify-content: center;
  position: absolute;
  bottom: 65px;
  right: 5px;
  width: 25px;
  z-index: 99999;
}

.tude-video-close-button {
  right: 0px;
  bottom: 150px;
  top: unset;
}

.test-ad {
  height: 225px;
  background-color: black;
}

@media only screen and (max-width: 600px) {
  .ae-fixed {
    bottom: 70px;
  }

  .ae-video-player {
    position: fixed;
    bottom: 170px;
  }

  .ae-anchor-close {
    margin-bottom: 24px;
  }
}
