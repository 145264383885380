.latest-catalog-item {
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 16px;
}

.latest-catalog-amount {
  padding: 2px 10px;
  border-radius: 8px;
}

.latest-catalog-actions {
  text-align: center;
}
