.footer a {
  transition: 0.3s;
}

.footer a:hover {
  color: lightgray;
}

.footer-top {
  display: flex;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.footer-top-half {
  display: flex;
  text-align: left;
  margin-bottom: 20px;
  justify-content: space-between;
}

.footer-bottom-half {
  display: flex;
}

.footer-top-column {
  flex-basis: 25%;
  text-align: left;
  margin-right: 50px;
}

.faq-links {
  display: flex;
  margin-right: 5px;
  text-decoration: none;
}

.faq-links:hover {
  text-decoration: underline;
}

.footer-socials {
  display: flex;
  margin-bottom: 10px;
}

.social-links {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  text-decoration: none;
}

.social-media-icon {
  font-size: 30px;
  width: 30px;
  margin-right: 10px;
}

.patreon {
  align-items: center;
  backface-visibility: hidden;
  background-color: rgb(232, 91, 70);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  height: unset;
  justify-content: center;
  pointer-events: unset;
  text-align: center;
  text-transform: none;
  user-select: none;
  white-space: unset;
  color: rgb(255, 255, 255) !important;
  font-size: 0.875rem !important;
  border-radius: 9999px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(232, 91, 70);
  border-image: initial;
  padding: 0.46875rem 1rem;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
  margin-top: 10px;
}

.patreon-icon {
  height: 1rem;
  width: 1rem;
  fill: white;
  margin-right: 10px;
}

.footer-social {
  font-size: 30px;
  margin: 0 10px;
}

.footer-link {
  cursor: pointer;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.discord-bot {
  background-color: #7289da !important;
}

.discord-bot:hover {
  background-color: #4e5d94 !important;
}

@media only screen and (max-width: 600px) {
  .footer-top {
    flex-direction: column;
    padding: 0;
  }

  .footer-top-column {
    flex-basis: auto;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .footer-bottom-half {
    flex-direction: column;
  }
}

.lang-select {
  min-width: 150px;
}
