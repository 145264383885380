.rainbow-container {
  display: flex;
  margin-right: 15px;
  margin-left: 15px;
}

.banner-slider-tooltip {
  position: absolute;
  left: 95px;
  width: 200px;
}

@media only screen and (max-width: 600px) {
  .banner-slider {
    padding: 0px 10px;
  }

  .banner-slider-close {
    margin-left: -15px;
    margin-right: 10px;
  }
}
