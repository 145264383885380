input,
textarea {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgray;
  padding: 7px 10px;
  font-size: 15px;
  border-radius: 5px;
}

.input-row {
  margin-bottom: 15px;
}

.input-label {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 15px;
  margin-bottom: 5px;
}

.content {
  display: flex;
  justify-content: center;
  padding: 15px;
}

.container {
  max-width: 100%;
}

.scroll-to-top {
  display: block;
  position: fixed;
  font-size: 25px;
  bottom: 275px;
  right: 20px;
  background-color: transparent;
}

.scroll-to-top:hover {
  background-color: transparent;
  cursor: pointer;
}

.btn-bar {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.btn-bar button:last-child {
  margin-left: 10px;
}

.disabled-link {
  pointer-events: none;
}

.error {
  color: red;
}

.capitalize {
  text-transform: capitalize;
}

.vm-footer {
  z-index: 9999;
}

@keyframes spin {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  #rich_media {
    bottom: 50px !important;
  }

  #rich_media-close {
    bottom: 80px !important;
  }

  #trendi_video {
    bottom: 170px !important;
  }

  #nitro-float-close {
    bottom: 236px !important;
  }

  .container {
    flex: 1;
    width: 100%;
  }

  .scroll-to-top {
    bottom: 60px;
  }

  .vm-footer {
    margin-bottom: 50px;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Toastify__toast-body {
  color: black;
  font-family: 'Baloo 2';
}

.Toastify__progress-bar {
  background: #02b202;
}

.Toastify__close-button {
  min-width: auto;
}
.Toastify__close-button:hover {
  background-color: transparent;
}

.image-gallery-icon:hover {
  background: transparent;
}

.image-gallery-bullet {
  min-width: 0 !important;
}

.fade {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  /* animation-fill-mode: forwards; */
}

.slowFade {
  animation: fadeInAnimationFast ease 0.25s;
  animation-iteration-count: 1;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInAnimationFast {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

#editorjs img {
  width: auto;
}
