.tags-rm {
  padding: 0 !important;
  color: gray;
  vertical-align: middle;
  margin-left: 7px;
}

.tags-rm:hover {
  background: none !important;
  color: lightgray;
}
