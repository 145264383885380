.alert {
  position: fixed;
  top: 20px;
  right: 20px;
  min-width: 300px;
  max-width: 300px;
  min-height: 150px;
  border-radius: 5px;
  z-index: 9999;
}

.alert-container {
  padding: 16px;
  text-wrap: auto;
}

.alert-header {
  display: flex;
  flex-direction: row;
  font-size: 18px;
}

.alert-content {
  padding-left: 28px;
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .alert {
    margin-top: 40px;
    right: 10px;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%) scale(0.8);
    opacity: 0;
  }
  60% {
    transform: translateX(0) scale(1.1);
    opacity: 1;
  }
  100% {
    transform: translateX(0) scale(1);
  }
}
