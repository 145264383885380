.badges-page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.badges-page-container .container {
  flex-direction: column;
  width: 100%;
  padding: 0px 40px;
}

.featured-badge-container {
  margin-top: -24px;
}

.featured-badge-header {
  text-align: left;
}

.badges-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding: 16px 8px;
  border-radius: 10px;
  margin-top: 24px;
  margin-bottom: 16px;
  width: 360px;
}

.badges-pagination {
  width: 360px;
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}

.badges-action-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.akrew-pts-shop-link {
  font-weight: 600;
  font-size: 20px;
}

.badge-item {
  cursor: pointer;
}

.badge-item-locked {
  cursor: pointer;
  filter: brightness(50%);
}

.badge-locked {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  color: #f9fafb;
  z-index: 2;
}

@media only screen and (max-width: 600px) {
  .edit-profile-page {
    flex-direction: column;
  }

  .featured-badges-section,
  .featured-badge-container,
  .badges-action-bar {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .badges-list {
    width: 100%;
  }

  .featured-badges-section {
    align-items: center;
  }

  .badges-save-btn {
    width: 90px;
  }

  .badges-remove-btn {
    width: 90px;
  }

  .akrew-pts-shop-link {
    margin-top: 8px;
  }
}
