.listing-item-link-gallery {
  flex-direction: column;
}

.listing-name {
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
}

.selling-listing:hover {
  color: #88c9ff;
}

.selling-listing-properties {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  font-weight: normal;
  color: gray;
  word-break: break-word;
}

.listing-accepted {
  display: flex;
  color: #15b14b;
}

.listing-expired {
  display: flex;
  align-items: center;
  color: red;
}

.offers-only-info {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 32px;
}

.trade-value {
  display: flex;
  flex-direction: column;
}

.wfl-link {
  font-size: 12px;
}

.add-pref {
  color: #0eb8d3;
  border-radius: 15px;
  margin-left: 5px;
}

/* LISTING ITEM */
.listing-item-body-col-1 {
  max-width: 160px;
}

/* LISTING SELLER */
.listing-seller-container {
  display: flex;
  flex-direction: row;
}

.listing-seller-username-container {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

/* ListingProductInfo */
.listing-info-compact-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: 24px;
}
