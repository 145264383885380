/* Tabs */
.edit-profile-title {
  font-size: 1.5em;
}

.edit-profile-title,
.tab-name {
  text-align: left;
}

.edit-profile-drawer {
  /* width: 340px; */
  width: 33%;
}

.edit-profile-tabs {
  margin-bottom: 16px;
}

.edit-profile-tab {
  padding: 10px 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.edit-profile-tab:hover {
  cursor: pointer;
}

.edit-profile-tab .right-arrow {
  margin-right: 40px;
}

.tab-name {
  margin: 0;
}

.akrew-pts-shop-link {
  font-weight: normal;
  font-size: 1.17em;
}

/* Save Button */
.save-btn {
  margin-top: 32px;
}

@media only screen and (max-width: 600px) {
  .edit-profile-title {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }
}
