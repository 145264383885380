.see-all {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
}

.see-all-btn-bar {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
