.profile-info {
  display: flex;
  margin-right: 20px;
}

.profile-pic {
  margin: 20px;
}

.profile-role-reviews {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  max-width: 250px;
}

.profile-rating {
  display: flex;
  align-items: center;
  border: none;
}

.profile-user-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.profile-followers {
  display: block;
  margin-right: 15px;
}

.profile-followers-value {
  font-size: 18px;
  line-height: 0.8;
  font-weight: bold;
  margin-right: 7px;
}
.profile-followers-text {
  font-size: 13px;
}

.profile-timezone {
  display: flex;
  margin-right: 10px;
}

.profile-bio {
  display: flex;
  overflow: hidden;
  padding: 10px;
  border-radius: 20px;
}

.profile-bio-edit {
  flex: 1;
}

.island-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
  margin-right: 10px;
}

.profile-contacts {
  display: flex;
  flex-wrap: wrap;
}

.profile-contact {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 10px;
}

.profile-edit {
  display: inline-block;
  max-width: 500px;
  overflow: hidden;
}

.profile-follows {
  display: block;
}

.report-user-btn {
  position: relative;
  background: none;
  color: #ea685e;
  font-size: 25px;
  padding: 0;
  min-width: auto;
  margin-left: 10px;
  margin-top: 5px;
}

.report-user-btn:hover {
  background: none;
}

.report-user-btn:hover .report-user-hover {
  display: block;
}

.report-user-hover {
  display: none;
  background: rgb(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  color: white;
  left: 50%;
  transform: translateX(-50%);
  bottom: -60%;
  font-size: 14px;
  white-space: nowrap;
}

.follow-btn {
  padding: 5px 15px;
  background-color: #ecb401;
}

.follow-btn:hover {
  background-color: #dea901;
}

.follow-btn-alt {
  background-color: transparent;
  color: #ecb401;
  border: 2px solid #ecb401;
}

.follow-btn-alt:hover {
  background-color: #ecb401;
  color: white;
}

.profile-playerinfo-edit {
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
}

.avatar-profile-btn {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  color: white;
}

.avatar-profile-desktop {
  display: block;
}

.avatar-profile-mobile {
  display: none !important;
}

/* Admin Actions */
.profile-page-admin-actions-container {
  border-radius: 30px;
  padding: 16px;
  margin-top: 16px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

.admin-actions-title {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  font-size: 20px;
}

.admin-actions-profile-page {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
  .profile-role-reviews {
    margin-right: 0;
    flex-direction: row;
    max-width: 600px;
  }

  .profile-info {
    flex-direction: column;
    margin-right: unset;
  }

  .profile-pic {
    margin-right: 10px;
  }

  .profile-frame {
    height: 133.33px !important;
    width: 133.33px !important;
    top: -16.66px !important;
    left: -16.66px !important;
  }

  .profile-follows {
    display: flex;
  }

  .avatar-profile-desktop {
    display: none;
  }

  .avatar-profile-mobile {
    display: block !important;
  }
}
