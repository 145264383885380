.edit-timezone,
.edit-languages {
  display: inline-block;
  width: 300px;
}

.edit-username {
  display: block;
}

.edit-bio {
  display: block;
  padding: 16px;
  border-radius: 10px;
  min-width: 400px;
  width: 100%;
}

.profile-edit-contacts {
  width: 300px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.profile-contact {
  display: flex;
  align-items: center;
}

.profile-contact a {
  display: flex;
  align-items: center;
}

.profile-edit {
  display: flex;
  align-items: center;
}

.profile-playerinfo-edit-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.username-color-sample {
  height: 25px;
  width: 25px;
  margin-right: 5px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.unlink-icon {
  background: none;
  padding: 0;
  min-width: 0;
  font-size: 12px;
  margin-left: 4px;
  cursor: pointer;
}

.unlink-icon:hover {
  background: none;
  color: red;
}

@media only screen and (max-width: 600px) {
  .edit-bio {
    min-width: 300px;
    width: 100%;
  }
}
