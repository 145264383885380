.avatar-page {
  padding: 30px 0;
}

.avatar-mobile {
  display: none;
}

.avatar-container {
  display: flex;
}

.avatar-game-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 15px;
}

.avatar-iframe {
  aspect-ratio: 1.77777777778;
  max-height: 100vh;
}

.avatar-game {
  width: 100%;
  height: 100%;
  border: none;
}

.avatar-btn-bar {
  text-align: center;
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .avatar-mobile {
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .avatar-mobile-home {
    justify-content: center;
  }

  .avatar-container {
    display: none;
  }
}
