.search-content {
  display: flex;
  margin-bottom: 30px;
}

.items {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px;
}

.item {
  margin-bottom: 20px;
}

.item-skeleton {
  position: relative;
  height: 0;
  padding-bottom: 100%;
}

.item-skeleton-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.item-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  position: relative;
  padding: 10px;
}

.item-img-container:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.item-img-container-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-img {
  display: block;
  position: relative;
  height: 128px;
  width: 128px;
  margin: auto;
  overflow: hidden;
}

img {
  width: 100%;
}

.item-categories {
  display: flex;
  overflow: auto;
  margin-bottom: 10px;
}

.page-bar {
  display: flex;
  justify-content: space-between;
}

.no-items {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: gray;
  margin-bottom: 20px;
}

.search-suggest {
  text-align: center;
}

.search-suggest a {
  display: inline-block;
}

.search-filters-all {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}

.search-filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.search-filter {
  margin-left: 7px;
}

.search-filter input {
  vertical-align: middle;
}

.search-diy-filter {
  width: auto;
}

.item-catalog {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 20px;
  cursor: pointer;
}

.item-wishlist {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  height: 24px;
  padding: 2px;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.page-numbers {
  display: flex;
}

.page-number:hover {
  background: rgb(128, 128, 128, 0.5);
}

.page-ellipsis {
  font-size: 25px;
  height: 40px;
  width: 20px;
  text-align: center;
}

.product-diy-icon-search {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
}

.search-tags-btn {
  display: none;
}

.search-filter-right {
  display: flex;
}

.wfl-link {
  margin: 0px 20px;
}

.search-sort {
  min-width: 200px;
}

.cover-upload-url {
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .search-tags-btn {
    display: inline-block;
  }

  .search-filters-all {
    flex-direction: column;
  }

  .search-filters {
    margin-bottom: 15px;
  }

  .search-filter-right {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    gap: 8px;
    row-gap: 8px;
  }

  .wfl-link {
    margin: 0;
  }

  .search-sort {
    margin-right: 10px;
  }

  .desktop-variant-name {
    display: none;
  }
}
