.home-section {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 20px;
}

.home-start-banner {
  padding-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
}

.home-start-banner span {
  width: 33%;
  padding: 32px;
  text-align: center;
  font-size: 24px;
  font-weight: bolder;
  color: white;
  border-radius: 30px;
}

.twitch-slide {
  position: relative;
  height: 0;
  padding-top: 29.5%;
}

.twitch-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: black;
  border-radius: 20px;
}

.yt-slide {
  position: relative;
  height: 0;
  padding-top: 29.5%;
  border-radius: 20px;
}

.yt-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 20px;
}

.yt-slide-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  filter: blur(8px);
  z-index: 1;
  border-radius: 20px;
}

.yt-profile {
  max-width: 180px;
}

.yt-thumbnail {
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 100;
  flex: 1;
  max-width: 480px;
}

.cover-slide {
  min-height: 287.91px;
  overflow: hidden;
}

.cover-slide-title {
  font-size: 50px;
}

.home-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  padding: 15px;
  text-decoration: none;
}

.home-wide-card {
  display: block;
  text-decoration: none;
  border-radius: 20px;
  font-size: 18px;
  font-weight: bold;
  padding-top: 20px;
  margin-bottom: 15px;
  background-repeat: repeat;
}

.home-card-img {
  display: block;
  border-radius: 20px;
  transition: 0.2s;
}

.home-img-skeleton {
  position: relative;
  height: 0;
  padding-bottom: 54%;
}

.home-img-skeleton-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.home-help {
  background-repeat: repeat;
  background-color: #ffecb7;
  padding-bottom: 30px;
  margin-bottom: 0;
}

.home-help-card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  border-radius: 20px;
  max-width: 250px;
  margin: auto;
}

.home-help-card:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.home-help-icon {
  display: flex;
  flex-direction: column;
  position: absolute;
  text-transform: uppercase;
}

.home-browse {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 20px;
}

.home-categories {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.home-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  font-size: 20px;
}

.home-category-img {
  display: flex;
  border-radius: 50%;
  max-width: 148px;
  max-height: 148px;
  padding: 20px;
  margin-bottom: 10px;
}

.home-suggest {
  min-height: 150px;
  border-radius: 20px;
  padding: 15px;
  border: 2px solid white;
  display: flex;
  align-items: center;
}

.cover-desktop {
  display: block;
  border-radius: 20px;
}

.cover-mobile {
  display: none;
  border-radius: 20px;
}

.banner-desktop {
  display: block;
  border-radius: 20px;
}

.banner-mobile {
  display: none;
  border-radius: 20px;
}

.patreon-gallery {
  padding-top: 30px;
}

.home-patrons {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 20px;
}

.streamer-link {
  display: flex;
  align-items: center;
  font-size: 30px;
  text-decoration: none;
  font-weight: bold;
}

.patron-link {
  display: flex;
  align-items: center;
  font-size: 30px;
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.image-gallery-image {
  border-radius: 20px;
}

.hiw-card {
  font-size: 20px;
}

.home-more-help {
  text-align: center;
}

.home-more-help a {
  display: inline-block;
}

.home-lf {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.game-verified svg {
  vertical-align: middle;
  color: #60bcb5;
}

@media only screen and (max-width: 600px) {
  .home-lf {
    display: block;
    gap: 0;
  }
}

.lf-toggle {
  display: none;
}

@media only screen and (max-width: 600px) {
  .lf-toggle {
    display: block;
  }

  .lf-header {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .cover-slide {
    min-height: 120.59px;
  }

  .home-start-banner {
    padding: 0 5%;
  }

  .home-start-banner span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
  }

  .twitch-slide {
    height: auto;
    padding-top: 0;
  }

  .twitch-frame {
    position: inherit;
  }

  .yt-slide {
    padding-top: 36%;
  }

  .yt-profile {
    display: none;
  }

  .yt-thumbnail {
    background-size: contain;
    max-height: 135%;
  }

  .cover-slide-title {
    font-size: 30px;
  }

  .cover-desktop {
    display: none;
  }

  .cover-mobile {
    display: block;
  }

  .banner-desktop {
    display: none;
  }

  .banner-mobile {
    display: block;
  }

  .home-category {
    margin-bottom: 20px;
  }
}
