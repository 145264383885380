.tag-category {
  font-weight: bold;
  text-transform: capitalize;
}

.tag-filters {
  margin-left: 10px;
  margin-bottom: 20px;
  overflow-y: scroll;
  max-height: 200px;
}

.tag-filter {
  text-transform: capitalize;
  cursor: pointer;
}

.tag-seemore {
  display: flex;
  align-items: center;
  padding: 5px;
  min-width: 50px;
  line-height: 1;
}

.tag-dropdown-filter {
  margin-bottom: 20px;
}

.tag-collapsible {
  cursor: pointer;
}

.tag-img {
  width: 16px;
  margin-right: 4px;
  vertical-align: middle;
}
