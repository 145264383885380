.login {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.login .container {
  max-width: 350px;
}

.or {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.or-line {
  flex: 1;
  border-top: 1px solid lightgray;
  margin: 0 10px;
}

.login-form {
  background: white;
  border-radius: 5px;
}

.login-btn-bar {
  text-align: center;
  margin-bottom: 20px;
}

.login-switch-container {
  text-align: center;
  margin-top: 8px;
  margin-bottom: 32px;
}

.preferred-platform {
  width: 100%;
  font-size: 15px;
  border-radius: 5px;
  padding: 10px;
}

.oauth-link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-decoration: none;
  font-family: 'Baloo 2';
}

.overwolf-login {
  width: 100%;
  font-weight: normal;
}

.discord-login {
  background-color: #7289da;
}

.discord-login:hover {
  background-color: #4e5d94;
}

.twitter-login {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #1da1f2;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-decoration: none;
  width: 100%;
  font-weight: normal;
  font-family: 'Baloo 2';
}

.twitter-login:hover {
  background-color: #1471aa;
}

.roblox-login {
  background-color: #f2f4f5;
  color: black;
  width: 100%;
}

.roblox-login:hover {
  background-color: #cdd0d2;
}

.battlenet-login {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-decoration: none;
  width: 100%;
  font-weight: normal;
  font-family: 'Baloo 2';
  background-color: #0074e0;
  color: white;
}

.battlenet-login:hover {
  background-color: #47a6ff;
}

.apple-login {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: black;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-decoration: none;
  width: 100%;
  font-weight: normal;
  font-family: 'Baloo 2';
}

.apple-login:hover {
  background-color: #2d2d2d;
}

.google-login {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #1a73e8;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-decoration: none;
  width: 100%;
  font-weight: normal;
  font-family: 'Baloo 2';
}

.google-login:hover {
  background-color: #2b7de9;
}

.facebook-login {
  background-color: #3578e5;
}

.facebook-login:hover {
  background-color: #4486f1;
}

.sign-up-link p {
  margin: 0 10px;
  text-align: center;
}

.signup-sublabel {
  color: gray;
  font-size: 13px;
  margin-top: 5px;
}

@media only screen and (max-width: 600px) {
  .login .container {
    margin-top: 35px;
  }
}
