.creators-container {
  background-color: #f99d78;
  padding: 20px;
  border-radius: 40px;
}

.creators-header {
  text-align: center;
  background-color: #f5f7e0;
  border-radius: 50px;
  color: #8a7768;
}

.creator-title {
  margin: 30px 0;
  font-size: 70px;
}

.creator-border {
  border-bottom: 4px dashed #f99d78;
  padding: 10px;
  margin: 0 15px;
}

.creators-creator {
  display: flex;
  color: #8a7768;
}

.creators-img {
  border-radius: 50%;
}

.creators {
  border-radius: 40px;
  background-color: #f5f7e0;
}

@media only screen and (max-width: 600px) {
  .creator-name {
    font-size: 20px;
  }

  .creator-title {
    font-size: 60px;
  }
}
