/* BALANCE */
.balance-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  border-radius: 6px;
  height: 64px;
}

.user-balance-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.your-balance-title {
  margin: 0;
  text-align: left;
}

/* ITEM */
.shop-item-container {
  border-radius: 20px;
}

.shop-item-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px;
  cursor: pointer;
  max-width: 160px;
}

.shop-item-username-color {
  height: 95px;
  width: 95px;
  border-radius: 50%;
}

.shop-item-img {
  width: 128px;
  height: 128px;
}

.shop-item-name {
  font-weight: bold;
  margin-bottom: 4px;
  text-align: center;
}

.shop-item-type-description {
  margin-bottom: 20px;
}

/* PRICE */
.price-container {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

/* Points Ad */
.gacha-modal-errmsg {
  color: red;
  font-size: 24px;
  display: flex;
  justify-content: center;
}

.gacha-modal-nobid,
.gacha-modal-error {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

@media only screen and (max-width: 600px) {
  .balance-container {
    font-size: 12px;
    height: 48px;
  }
}
