.user-search {
  display: flex;
  justify-content: center;
  padding: 15px;
}

.search-user {
  border-bottom: 2px dashed #88c9ff;
  padding: 10px;
}

.user-contacts {
  display: flex;
  flex-wrap: wrap;
}
