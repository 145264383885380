.upload-pfp-container {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.profile-frame {
  position: absolute;
  top: -20px;
  left: -20px;
  height: 160px;
  width: 160px;
  z-index: 1;
}

.profile-badge {
  position: absolute;
  bottom: -4px;
  right: -4px;
  height: 40px;
  width: 40px;
  margin-right: -5px;
}

.profile-icons-container,
.profile-frames-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  padding: 8px;
  width: 360px;
}

.pfp-icon-opt {
  border-radius: 50%;
  border: 3px solid #88c9ff;
  height: 50px;
  width: 50px;
  cursor: pointer;
  overflow: hidden;
}

.pfp-icon-opt img {
  border-radius: 50%;
}

.frame-opt {
  display: block;
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.frame-opt.remove-frame {
  border-radius: 50%;
  border: 3px solid red;
  font-size: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 10px;
}

.frame-locked {
  position: absolute;
  top: 11px;
  left: 13px;
  width: 25px;
  height: 25px;
  color: #f9fafb;
}

.profile-icons-pagination {
  width: 360px;
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .upload-pfp-container {
    gap: 0px;
  }
}
