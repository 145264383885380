.review-info-ul {
  list-style: none;
  margin-top: 0;
  padding-left: 10px;
}

.review-info-item {
  display: inline-flex;
}

.review-info-icon {
  margin-top: 5px;
  margin-right: 5px;
}

.review-info-icon.red {
  color: red;
}

.review-info-icon.green {
  color: green;
}
