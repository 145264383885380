.notification-content {
  display: flex;
}

.notification-title-container {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-title-container h1 {
  font-size: 1.5em;
}

.notification-title {
  font-size: 20px;
  font-weight: bold;
}

.notifications-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 500px;
  overflow: auto;
}

.notifications-list-page {
  max-height: unset;
}

.list-item {
  padding: 5px;
  border-bottom: 1px solid #909397;
  transition: 0.3s;
  cursor: pointer;
}

.list-item:hover {
  background-color: rgba(159, 198, 231, 0.2) !important;
}

.notification-footer {
  border-bottom: none;
}

.notification-title > p {
  margin: 0;
}

.notification-icons {
  float: left;
  position: relative;
  top: 3px;
  margin: 0 5px 0 0;
}

.notification-date {
  font-size: 13px;
  color: gray;
}

.notif-bell-icon {
  position: relative;
  margin: auto 0;
}

.notif-bell-icon .badge {
  position: absolute;
  right: 0;
  bottom: 5px;
  border-radius: 50%;
  width: 14px;
  line-height: 14px;
  background: red;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.notif-bell-icon .badge-plus {
  font-size: 10px;
  line-height: 12px;
  border-radius: 25px;
  width: 24px;
  left: 14px;
}

.disabled-btn {
  pointer-events: none;
  cursor: not-allowed;
}

@media only screen and (max-width: 600px) {
  .notif-bell-icon .badge {
    bottom: 12px;
    right: 12px;
  }

  .notif-bell-icon .badge-plus {
    font-size: 10px;
    line-height: 12px;
    border-radius: 25px;
    width: 24px;
    left: 30px;
  }
}
