.chat {
  display: flex;
  flex: 1;
  max-width: 100%;
  max-height: calc(100vh - 80px);
  border-top: 1px solid rgb(123, 123, 123, 0.3);
}

.go-back-chat {
  margin-top: 4px;
}

.chat-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.conversations {
  min-width: 250px;
  padding: 10px;
  overflow: auto;
}

.convo {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 15px;
  transition: 0.3s;
  text-transform: none !important;
}

.convo:hover {
  background-color: rgba(167, 51, 51, 0.3);
}

.convo-active {
  background-color: rgb(123, 123, 123, 0.3);
}

.convo-info {
  display: flex;
  justify-content: space-between;
}

.no-conversations {
  padding: 10px;
}

.message-offer {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-radius: 20px;
  margin-bottom: 15px;
}

.offer-completed-confirm {
  text-align: center;
}

.offer-completed-confirm-title {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.chat-header {
  display: flex;
  align-items: center;
}

.chat-header input {
  width: auto;
  height: auto;
}

.convo-show {
  transform: rotate(-180deg);
  transition: 0.3s;
}

.convo-hide {
  transition: 0.3s;
}

.chat-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(123, 123, 123, 0.3);
  padding: 10px 15px;
}

.archive-all-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -50px;
  margin-top: 15px;
}

.chat-archive {
  background: transparent;
  padding: 0;
  font-size: 25px;
  min-width: 0;
  line-height: 1;
  color: transparent;
}

.chat-archive:hover {
  background: transparent;
  color: gray;
}

.chat-archive:disabled,
.chat-archive:disabled:hover {
  background: transparent;
  color: gray;
  cursor: not-allowed;
}

.chat-reopen {
  width: 100%;
  margin-bottom: 10px;
}

.chat-archive-all {
  font-size: 20px;
  align-items: center;
  line-height: 0;
  width: auto;
  margin: 0 auto;
}

.chat-search-close {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.archive-chat-option {
  text-align: left;
  clear: both;
  display: flex;
  margin-right: 5px;
  align-items: center;
}

.chat-report {
  padding: 10px 15px;
}

@media only screen and (min-width: 600px) {
  .conversations {
    max-height: 90vh;
    border-right: 1px solid rgb(123, 123, 123, 0.3);
  }

  .reopen-bar {
    display: flex;
  }

  .chat-reopen {
    width: unset;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .conversations {
    padding: 0 25px;
    width: 100%;
    border: none;
  }

  .go-back-chat {
    margin-top: 16px;
  }

  .chat-content {
    margin: 0;
    overflow: auto;
  }

  .convo {
    border-radius: 15px;
  }

  .message-offer {
    flex-direction: column;
  }

  .chat-archive {
    display: none;
  }
}

@media only screen and (min-width: 92em) {
  .chat {
    width: 71rem;
    margin: auto;
  }
}
