.item-currencies {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.item-currencies .listing-bells {
  justify-content: center;
}

.item-props {
  margin-bottom: 10px;
}

.item-props-group {
  font-weight: bold;
  margin-bottom: 5px;
}

.wfl-item-img {
  display: block;
  position: relative;
  height: 50px;
  width: 50px;
  margin: auto;
  overflow: hidden;
}

.item-prop {
  display: block;
  margin-bottom: 10px;
}

.item-prop-img {
  width: 50px;
  vertical-align: middle;
}

.item-prop-color {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 5px;
}

@media only screen and (min-width: 600px) {
  .item-currencies .listing-bells:not(:last-child) {
    margin-right: 5px;
  }

  .wfl-item-img {
    height: 96px;
    width: 96px;
  }
}

@media only screen and (max-width: 600px) {
  .item-currencies {
    flex-direction: column;
  }

  .wfl-items .row .col-xs-6 {
    padding: 0 8px 0 0 !important;
  }
}
