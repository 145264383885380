/* Achivement */
.achievement-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 350px;
}

.achievement-info {
  display: flex;
  flex-direction: column;
}

.achievement-title {
  margin: 0;
  text-align: left;
}

.achievement-description {
  text-align: left;
}

/* Achievement Mini */
.achievement-mini-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

/* Badge */
.achievement-badge {
  position: relative;
}

.achievement-badge-img {
  width: 95px;
  height: 95px;
}

.achievement-badge-img-locked {
  filter: brightness(50%);
}

.achievement-badge-locked {
  position: absolute;
  z-index: 2;
  top: 30px;
  left: 32.5px;
  width: 30px;
  height: 30px;
  color: #f9fafb;
}

/* Progress Bar */
.achievement-progress-bar-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
}

.achievement-progress-bar-container.completed {
  gap: 12px;
}

.achievement-progress-bar-outer {
  width: 175px;
  border-radius: 10px;
  background-color: lightgray;
}

.achievement-progress-bar-inner {
  height: 15px;
  border-radius: 10px;
}

.achievement-progress-score {
  font-weight: bold;
  color: gray;
  margin: 0;
}

/* Achievement Popup */
.achievement-popup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.achievement-popup-header h1,
.achievement-popup-header p {
  text-align: center;
  margin: 0;
}

.achievement-popup-header h1 {
  margin-top: -8px;
  font-size: 1.7em;
}

.achievement-popup-header p {
  color: gray;
}

.achievement-popup-body {
  margin-top: 24px;
}

.achievement-popup-footer {
  margin-top: 48px;
  margin-bottom: 4px;
}

.achievement-btn-bar {
  justify-content: center;
}
