.wishlist-img-container {
  border: 20px solid #88c9ff;
  border-radius: 20px;
  background-color: #fff;
  width: 500px;
  height: 500px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.wishlist-img-container h2 {
  color: #000;
  margin-top: 4px;
  text-align: center;
}

.wishlist-listings-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 8px;
  padding: 0px 8px;
  margin-top: -12px;
}

.wishlist-dl-btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
