.listings-actions {
  display: flex;
  margin-bottom: 15px;
}

.listing-add-set {
  display: flex;
  align-items: center;
}

.listing-set-select {
  min-width: 200px;
  margin-right: 15px;
}
