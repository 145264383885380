/* ARTICLES MAIN PAGE */
.articles-page {
  display: flex;
  justify-content: center;
}

.articles-page-container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 64px;
}

/* ARTICLE PAGE */
.article-page {
  display: flex;
  justify-content: center;
  margin-bottom: 64px;
}

.article-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: -8px;
}

.article-page-edit-bar {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.article-inactive-msg {
  color: red;
  font-weight: bold;
}

.article-title {
  font-size: 36px;
  margin-bottom: 4px;
  text-align: left;
}

.article-subtitle {
  font-size: 18px;
  font-weight: normal;
  color: gray;
  margin: 0;
  text-align: left;
}

.article-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.article-subdetails {
  font-style: italic;
  color: gray;
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.article-page-content {
  margin-bottom: 64px;
}

.article-page-content p,
.article-page-content h2 {
  text-align: left !important;
}

.article-error {
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 64px auto;
  gap: 16px;
}

/* CREATE ARTICLE PAGE */
.create-article-page {
  display: flex;
  justify-content: center;
}

.create-article-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.article-action-btn-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

/* editorjs */
#editorjs {
  border-radius: 10px;
  margin-bottom: 16px;
}

.ce-inline-toolbar {
  color: black;
}

@media only screen and (max-width: 600px) {
  .articles-page,
  .create-article-page,
  .article-page {
    margin: 0px 20px;
  }

  .article-details,
  .article-subdetails {
    flex-direction: column;
    justify-content: flex-start;
    align-items: first baseline;
  }
}
