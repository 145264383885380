.listing-side-desktop {
  display: block;
  padding: 10px;
  border-radius: 20px;
}

.listing-side-mobile {
  display: none;
}

.close-tags-drawer-btn {
  display: none;
  background: none;
  font-size: 20px;
  padding: 0;
  min-width: 0;
  float: right;
}

.filter-toggle-arrow {
  font-size: 12px;
  float: right;
  margin-right: 10px;
  margin-top: 4px;
}

@media only screen and (max-width: 600px) {
  .close-tags-drawer-btn {
    display: flex;
  }

  .listing-side-filters {
    width: auto;
    border: none;
    margin-right: 0px;
    padding-right: 0px;
  }

  .listing-side-types {
    display: flex;
    overflow: auto;
    margin-bottom: 5px;
  }
}
