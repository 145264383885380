.product {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 15px;
}

.product-display {
  display: flex;
  flex-direction: column;
}

.product-info {
  flex: 1;
  width: 100%;
}

.product-img-container {
  margin-bottom: 10px;
}

.product-img {
  position: relative;
  width: 180px;
  height: 180px;
  padding: 15px;
  border-radius: 20px;
  margin: auto;
  margin-bottom: 5px;
}

.product-diy-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
}

.product-any-icon {
  position: absolute;
  bottom: 0;
  right: 10px;
  width: 50px;
}

.product-sticker-icon {
  position: absolute;
}

.product-name h1 {
  font-size: 30px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}

.item-name-edit {
  font-size: 30px;
  font-weight: bold;
  text-transform: capitalize;
}

.product-type {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.product-value {
  display: flex;
  flex-wrap: wrap;
}

.product-value-header {
  margin: 0 0 5px 0;
}

.product-price {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 5px;
}

.product-obtained {
  margin-bottom: 5px;
}

.product-variants {
  display: flex;
  overflow: auto;
}

.product-variants-mobile {
  display: none;
}

.product-variants::-webkit-scrollbar {
  background-color: transparent;
}

.product-variants::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.product-variants:hover::-webkit-scrollbar-thumb {
  background-color: #202224;
}

.product-variant {
  flex-shrink: 0;
  height: 50px;
  width: 50px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
}

.variants-more {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border: 1px solid black;
  border-radius: 5px;
  margin-top: 5px;
}

.category-icon {
  vertical-align: middle;
  width: 25px;
}

.product-action-bar {
  display: flex;
  flex-wrap: wrap;
}

.product-input {
  max-width: 200px;
}

.image-upload-section {
  max-width: 200px;
}

.create-listing-sections-container.compact {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.create-listing-selling-container.compact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.create-listing-section {
  margin-bottom: 20px;
}

.create-listing-section.compact {
  padding: 32px 32px;
  border-radius: 6px;
  width: 49%;
}

.create-listing-section.compact.product-page {
  width: 100%;
}

.create-listing-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.create-listing-title .secondary {
  font-size: 18px;
}

.create-listing-title.compact {
  display: flex;
  font-size: 24px;
  flex-direction: column;
}

.create-listing-title .create-listing-subtitle {
  font-weight: normal;
  font-size: 15px;
}

.create-listing-checks {
  display: flex;
  flex-wrap: wrap;
}

.create-listing-options {
  display: flex;
  flex-wrap: wrap;
}

.create-listing-diy {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
}

.create-listing-diy input {
  width: auto;
}

.create-listing-selling {
  display: flex;
}

.create-listing-btn {
  margin-right: 20px;
}

.create-listing-image {
  max-width: 500px;
}

.currency-input {
  text-transform: capitalize;
}

.edit-tags-select {
  margin-bottom: 10px;
}

.listing-option-select {
  min-width: 150px;
  max-width: 500px;
  margin-right: 16px;
  margin-bottom: 8px;
}

.listing-option-multiselect {
  min-width: 300px;
  max-width: 500px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.product-pricing-option {
  margin-right: 15px !important;
  margin-bottom: 10px !important;
}

.product-note {
  color: gray;
  margin-bottom: 5px;
}

.bells-icon {
  width: 20px;
  height: 20px;
  margin-right: 2px;
}

.resetti {
  width: 50px;
  margin-right: 10px;
}

.add-listing-container {
  margin-right: 10px;
  margin-bottom: 10px;
}

.create-listing-confirm {
  text-align: center;
}

.create-listing-confirm-title {
  font-size: 20px;
  margin-bottom: 15px;
}

.listing-auction-input {
  max-width: 200px;
}

.add-wishlist-select {
  min-width: 150px;
}

.wishlist-lookingfor-select {
  min-width: 300px;
  max-width: 500px;
  margin-bottom: 15px;
}

.item-row-container {
  align-items: center;
}

.item-with-image {
  display: inline-block;
  vertical-align: middle;
  display: flex;
  padding-bottom: 4px;
}

.item-with-image-compact {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-wrap: wrap;
  border-radius: 6px;
}

.image-icon {
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.offer-table-content {
  margin-bottom: 10px;
}

.product-owned {
  display: flex;
  align-items: center;
  padding: 3px 10px 3px 5px;
  background-color: lightgray;
  color: gray;
  border-radius: 25px;
}

.wishlist-msg {
  transition: 0.3s;
}

.offer-amount-input {
  font-size: 15px;
}

.offer-table-group-checks {
  margin-top: 16px;
}

.offer-table-select {
  max-width: 500px;
  margin-bottom: 8px;
}

.properties-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.product-tab-text {
  vertical-align: middle;
}

.edit-item-date-btn {
  display: flex;
}

.value-props {
  width: 100%;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .product-variants {
    display: none;
  }

  .product-variants-mobile {
    display: block;
  }

  .add-listing-container {
    width: 100%;
    margin-right: 0;
  }

  .product-input {
    max-width: 100%;
  }

  .listing-auction-input {
    max-width: 100%;
  }

  .create-listing-sections-container.compact {
    flex-direction: column;
    justify-content: flex-start;
  }

  .create-listing-section.compact {
    width: 100%;
  }

  .add-listing-container button {
    width: 100%;
  }
}

.notice {
  color: black;
  background-color: #fbdfe4;
  border-radius: 5px;
  padding: 5px;
  border: 4px solid pink;
  margin-top: 15px;
  margin-bottom: 15px;
}
