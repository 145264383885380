.listing {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.listing-page-img-container {
  border-radius: 20px;
  margin: 0 20px 5px 0;
}

.listing-page-item-img {
  width: 100%;
  min-height: 130px;
}

.listing-top {
  display: flex;
  flex: 1;
}

.listing-header-name {
  display: flex;
}

.listing-header-name h1 {
  margin: 0;
  font-size: 25px;
}

.listing-info {
  font-size: 18px;
  min-width: 500px;
  flex: 1;
}

.listing-info-icon {
  color: #88c9ff;
}

.listing-info-icon:hover {
  cursor: pointer;
}

.listing-edit-icon {
  font-size: 16px;
}

.listing-edit-icon:hover {
  cursor: pointer;
}

.listing-report-tooltip {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.listing-report-icon {
  color: #838383;
}

.listing-report-icon:hover {
  cursor: pointer;
}

.listing-page-price {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  overflow-y: auto;
  max-height: 450px;
}

.listing-seller-contact {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.listing-page-user {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 15px;
}

.other-listings-header {
  font-size: 18px;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .listing-top {
    flex-direction: column;
  }

  .listing-header-name {
    flex-wrap: wrap;
  }

  .listing-page-img-container {
    text-align: center;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .listing-info {
    min-width: auto;
  }

  .listing-page-item-img {
    width: 150px;
    min-height: 150px;
  }
}
