/* === ITEM PAGE === */
.shop-item-page-container {
  display: flex;
  justify-content: center;
  margin-bottom: 64px;
}

/* HEADER */
.shop-item-page-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 48px;
}

.shop-item-page-title {
  display: flex;
  flex-direction: column;
}

.shop-item-page-title h1 {
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0;
}

.shop-item-page-title p {
  text-align: left;
  font-size: 1.2em;
}

.shop-refresh-time {
  display: flex;
  align-items: center;
}

/* BODY */
.shop-item-page-body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shop-item-page-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 20px;
  width: 200px;
  margin-bottom: 16px;
  position: relative;
}

.shop-item-box-img {
  width: 95px;
  height: 95px;
}

.shop-item-page-img {
  width: 150px;
  height: 150px;
}

.shop-item-page-description {
  text-align: center;
  margin-bottom: 16px;
}

/* BUY PREVIEW MODAL */
.cosmetic-preview-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cosmetic-preview-container h2 {
  margin: 0;
}

.cosmetic-preview-container p {
  margin-bottom: 16px;
}

.cosmetic-preview-body {
  margin-top: -24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* align-self: flex-start; */
}

.cosmetic-preview-user {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cosmetic-preview-message {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.cosmetic-preview-message-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.message-content {
  padding: 10px;
  border-radius: 5px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  position: relative;
  background-color: rgb(95, 160, 215, 0.3);
}

/* GACHA MODAL */
.gacha-modal-btn-bar {
  display: flex;
  justify-content: center;
}

.gacha-animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gacha-scene {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.gacha-scene-body {
  margin-bottom: 16px;
}

.gacha-item-tags {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

/* ================= */

/* === SHOP PAGE === */
.akrew-shop-page-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

/* HEADER */
.shop-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* NAV TABS */
.nav-bottom {
  display: flex;
  overflow: auto;
  justify-content: space-between;
  margin-top: 8px;
}

/* BANNER */
.shop-banner {
  margin-top: 24px;
  width: 100%;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.banner-text {
  color: black;
  margin-bottom: 16px;
  margin-top: 16px;
  font-size: 1.5em;
  font-weight: 600;
}

/* BODY */
.shop-body {
  margin-top: 24px;
  margin-bottom: 40px;
}

.shop-body-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.shop-body-header h2 {
  text-align: left;
}

.shop-items-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}

.shop-page-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.shop-search-bar {
  width: 250px;
}

.gacha-details-container hr {
  display: block;
  height: 1px;
  border: 0;
  clear: both;
  border-top: 1px solid lightgray;
  margin: 3em 0;
  padding: 0;
  width: 80%;
}

.gacha-rates-table-title {
  margin: 0;
}

.gacha-rates-table {
  width: 30%;
  text-align: left;
  margin: 0 auto;
  border: 2px solid lightgray;
}

.gacha-rates-table td,
.gacha-rates-table th {
  border: 2px solid gray;
}

.gacha-rates-table tr:nth-child(even) {
  background-color: lightgray;
  color: black;
}

.gacha-items-list-images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  gap: 8px;
  row-gap: 8px;
}

.gacha-items-list-image-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.gacha-items-list-text {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.gacha-items-list-and {
  margin-top: 16px;
}

.gacha-pagination {
  display: flex;
  justify-content: space-between;
}

/* ================= */

/* === HOW POINTS WORK PAGE === */
.howpointswork-btns {
  display: flex;
  flex-direction: column;
  padding-right: 24px;
}
/* ================= */

.shop-avatar-btn {
  display: block;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .akrew-shop-page-container,
  .shop-item-page-container {
    padding: 0px 20px 40px 20px;
  }

  .shop-header h1 {
    font-size: 1.45em;
  }

  .balance-container {
    font-size: 12px;
    height: 48px;
  }

  .nav-bottom {
    justify-content: space-between;
  }

  .shop-items-container {
    justify-content: space-around;
  }

  .shop-item-page-header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 32px;
  }

  .shop-item-page-balance {
    align-self: center;
    margin-top: 24px;
  }

  .shop-search-item {
    width: 100%;
  }

  .gacha-rates-table {
    width: 80%;
  }

  .howpointswork-col-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .howpointswork-btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin-top: 8px;
  }
}
