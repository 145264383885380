.review-star {
  color: #ff8e0a;
}

.user-profile-img {
  border-radius: 50%;
  max-width: 40px;
  margin-right: 7px;
  border: 2px solid #88c9ff;
}
