.report-container {
  display: flex;
  flex-direction: column;
}

.report-container > .report-header {
  text-align: center;
  margin-top: -24px;
}

.report-container > .report-header h1 {
  margin: 0;
}

.report-container > .report-header p {
  margin: 0;
  color: gray;
}

.report-reason {
  padding: 16px 0px;
  overflow: visible;
}

.report-btn-bar {
  justify-content: center;
}

.no-evidence-warning {
  color: gray;
}

.false-report-disclaimer {
  color: gray;
  /* padding: 0px 48px; */
}

.false-report-disclaimer p {
  color: gray;
  margin: 0;
}

.evidence-x-btn {
  position: absolute;
  color: white;
  right: 4px;
  top: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.chat-container {
  background-color: rgb(123, 123, 123, 0.3);
  border-radius: 12px;
  padding: 12px;
}
