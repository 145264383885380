.multi-select-btn-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 15;
  position: sticky;
  bottom: 0;
}

.multi-select-btn-bar-sidebar {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
}

.multi-select-btn-bar button {
  min-width: auto;
}

.multi-lock {
  display: flex;
  align-items: center;
}

.sidebar-property-dropdown {
  max-height: 100px;
  overflow-y: scroll;
  margin-left: 20px;
}
