.offer-btn-bar {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.offer-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.offer-row {
  flex: 1;
}

.offer-row .row {
  margin: 0 !important;
}

.offer-item {
  padding: 0 !important;
}

.offer-accept-btn {
  margin-right: 10px;
}

.offer-counter-btn {
  margin-right: 10px;
}

.offer-dodo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.offer-dodo input {
  width: auto;
}

.offer-dodo-edit {
  color: #0eb8d3;
  font-size: 20px;
}

.offer-contacts {
  display: flex;
  flex-wrap: wrap;
}

.offer-contact {
  display: flex;
  flex-wrap: wrap;
  margin-right: 5px;
  align-items: center;
}

.offer-bells-input {
  max-width: 200px;
}

.offer-accept-option {
  margin-top: 10px;
  display: flex;
}

.make-offer {
  border-radius: 20px;
  padding: 15px;
}

.offer-table-label {
  margin-bottom: 5px;
}

.input-row.compact {
  max-width: 320px;
  padding-right: 0px;
}

.offer-icon {
  max-width: 50px;
  max-height: 50px;
  margin-right: 10px;
}

.offer-icon-compact {
  position: relative;
  max-width: 50px;
  max-height: 50px;
}

.product-diy-icon-offer {
  position: absolute;
  max-width: 25px;
  right: 0;
  bottom: 5px;
}

.offer-tabel-amount {
  text-align: right;
}

.currency-padding {
  padding: 0 17px;
}

@media only screen and (max-width: 600px) {
  .offer-confirm {
    text-align: center;
  }

  .offer-confirm-title {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .currency-padding {
    padding: 0 12px;
  }
}

@media only screen and (max-width: 600px) {
  .offer-actions {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .offer-actions-row {
    display: flex;
  }

  .offer-action-btn-container {
    margin-right: 10px;
  }
}

.offer-catalog {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.offer-catalog-amount {
  position: absolute;
  left: 8px;
  bottom: 16px;
  padding: 2px 10px;
  border-radius: 8px;
}
