.cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.cart-table {
  width: 100%;
  border-collapse: collapse;
}

.cart-table tr {
  border-bottom: 2px dashed #88c9ff;
}

.cart-table td {
  padding: 15px 0;
}
