.values {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.values-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px 15px;
}

.values-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 16px;
}

.values-search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
}

.values-title {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.values-type-filters {
  display: flex;
}

.values-filter {
  margin-right: 10px;
  align-items: flex-start;
}

.values-type-select {
  width: 200px;
}

.values-variant-filters {
  display: flex;
  align-items: center;
}

.values-variant-filter {
  display: flex;
  margin-left: 7px;
}

.values-variant-filter input {
  vertical-align: middle;
}

.value-list {
  display: flex;
  width: 100%;
}

.value-list-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px 16px;
}

.value-info-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  column-gap: 16px;
}

.item-value {
  margin: 30px 0px;
  position: relative;
  width: 300px;
  max-width: 300px;
  max-height: 300px;
}

.item-value-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.item-name-price {
  margin-left: 32px;
  padding: 40px 0px;
}

.item-price {
  margin-left: 2px;
}

.item-value-img {
  max-height: 100px;
  max-width: 100px;
}

.item-value-grouping {
  display: flex;
}

.item-value-rank-line {
  position: relative;
}

.value-rank-edit-value-container {
  margin-left: 8px;
  max-width: 180px;
}

.value-rank-item-container {
  display: flex;
  flex-direction: column;
}

.item-value-obj {
  display: block;
  padding: 5px;
  margin: 10px;
  height: 130px;
  width: 130px;
  font-size: 14px;
  justify-content: center;
  text-align: center;
  align-content: center;
}

.value-table-item {
  position: relative;
}

.value-table-item-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
}

.value-table-item-num {
  text-align: center;
  font-weight: bold;
}

.value-table-item-name {
  white-space: nowrap;
  max-width: 100%;
  min-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  text-align: center;
}

.value-table-img {
  width: 60px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border-radius: 10px;
  overflow: hidden;
}

.value-table-item-info {
  display: flex;
  min-width: 100%;
}

.value-table-item-data {
  display: flex;
  flex-direction: column;
}

.value-table-item-data span {
  white-space: nowrap;
}

.values-sort-bar {
  width: 220px;
}

@media only screen and (max-width: 600px) {
  .values-container {
    width: 100%;
  }

  .values-sort-bar {
    width: 180px;
  }

  .values-type-filters {
    display: block;
  }

  .values-filter {
    margin-bottom: 10px;
  }
}
