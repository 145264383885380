.nav-mobile {
  display: none;
}

.nav-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-icons {
  display: flex;
  align-items: center;
}

.nav-login-btns {
  display: flex;
  align-items: center;
}

.nav-bottom {
  display: flex;
  overflow: auto;
  justify-content: space-between;
}

.nav-bottom::-webkit-scrollbar {
  background-color: transparent;
}

.nav-bottom::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.nav-bottom:hover::-webkit-scrollbar-thumb {
  background-color: #909397;
}

.logo {
  width: 150px;
  max-height: 33px;
}

.search {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 10px;
}

.search-type-input {
  min-width: 120px;
}

.search-right {
  display: flex;
  flex: 1;
}

.search-btn {
  display: flex;
  align-items: center;
  border: none;
  font-size: 30px;
  padding: 8px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  cursor: pointer;
  min-width: auto;
}

.search-select-value {
  color: white;
  font-weight: bold;
}

.search-select-value-mobile {
  display: none;
}

.profile-link {
  display: flex;
  align-items: center;
  margin-right: 5px;
  position: relative;
}

#game_home {
  position: relative;
}

#game_home .game-verified {
  font-size: 18px;
  position: absolute;
  bottom: -10px;
  right: 0;
}

.app-search {
  width: 100%;
}

@media only screen and (min-width: 600px) {
  .desktop-search {
    flex: 2 1;
  }
}

@media only screen and (max-width: 991px) {
  .search-select-value-desktop {
    display: none;
  }

  .search-select-value-mobile {
    display: block;
  }

  .search-type-input {
    min-width: 60px;
  }
}

@media only screen and (max-width: 1023px) {
  .nav-laptop {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .nav-container {
    padding: 0 15px;
  }

  .nav-mobile {
    display: flex;
    align-items: center;
  }

  .nav-desktop {
    display: none;
  }

  .nav-right {
    flex: 1;
    justify-content: flex-end;
  }

  .desktop-search {
    margin-right: 4px;
  }

  .search {
    padding: 0;
  }

  .nav-bottom {
    justify-content: space-between;
  }

  .search-right {
    width: 100%;
  }

  .profile-link-text {
    display: none;
  }
}
