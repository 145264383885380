.offer-actions {
  display: flex;
  flex-direction: column;
}

.offer-accept-deny {
  width: 100%;
}

.offer-action-btn-container button {
  width: 100%;
}

.offer-action-btn-container {
  max-width: 260px;
  margin-bottom: 10px;
}

.request-delete-info-container {
  display: flex;
  flex-direction: column;
}

.omw-btn {
  background-color: #0eb8d3;
}

.omw-btn:hover {
  background-color: #16c4e0;
}

.offer-modal {
  width: auto;
}

.counter-offers {
  margin-top: 0px;
  font-weight: bold;
  font-size: 18px;
}

.counter-expand {
  padding: 5px 15px;
  border-radius: 5px;
  border: 1px solid gray;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 10px;
}

.counter-expand:hover {
  border-color: lightgray;
}

.add-pref-input {
  display: flex;
}
