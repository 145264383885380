/* Overall container */
.edit-profile-page-container {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.edit-profile-page-container .container {
  display: flex;
  flex-direction: row;
}

/* Each page */
.edit-profile-page {
  padding-left: 40px;
}

.edit-profile-page h1 {
  font-size: 1.5em;
}

.edit-profile-page h2 {
  font-size: 1.17em;
}

.edit-profile-page .page-section {
  margin-top: 32px;
  margin-bottom: 64px;
  min-height: 60vh;
}

.saved-checkmark {
  color: #52c41a;
  margin-left: 8px;
  margin-bottom: -4px;
  font-size: 20px;
  transition: opacity 0.3s;
}

.saved-message {
  transition: opacity 0.3s;
}

@media only screen and (max-width: 600px) {
  .edit-profile-page-container .container {
    flex-direction: column;
  }

  .edit-profile-page {
    padding: 0px 20px;
  }

  .edit-profile-page-title {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }

  .edit-profile-page .page-section {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .page-section h2,
  .saved-message {
    text-align: left;
  }
}
