.achievements-page-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}

.achievement-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  row-gap: 16px;
}

@media only screen and (max-width: 600px) {
  .achievements-page-container {
    padding: 0px 20px 40px 20px;
  }

  .achievement-section {
    flex-direction: column;
    justify-content: flex-start;
  }
}
